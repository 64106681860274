<template lang="pug">
  tr.course-leader_item
    td.item_name(v-if="isByCourseTab")
      span(v-if="item && item.leaders && item.leaders.length")
        span(v-for="(name, index) in item.leaders" :key="index") {{ name.name }}
          span(v-if="index !== (item.leaders.length - 1)") ,&nbsp;
      span(v-if="!item || !item.leaders || !item.leaders.length") -

    td.item_date(v-if="isByCourseTab") {{ city }} {{ getCourseDate | formattedDate }}
    td.item_date-only(v-if="isByLeaderOnCourseTab") {{ getCourseDate | formattedDate }}
    td.item_date(v-if="isByLeaderOnCourseTab") {{ city }} {{ getCourseName }}

    td.item_name(v-if="(isByCourseLeadersTab || isByLeaderOnCourseTab) && item.leader") {{ item.leader.name }}
    td.item_name(v-if="(isByCourseLeadersTab || isByLeaderOnCourseTab) && !item.leader") -

    td.item_quantity-course(v-if="isByCourseLeadersTab") {{ item.course_count | zeroValue }}
    td.item_quantity-course(v-if="isByCourseLeadersTab") {{ item.has_course_photo | zeroValue }}

    td.item_sessions(v-if="isByLeaderOnCourseTab")
      span(v-for="(session, index) in item.sessions" :key="index" :class="{'active': isSessionActive(session.id)}") {{ session.short_name }}
        span(v-if="index !== (item.sessions.length - 1)") ,&nbsp;

    td.item_success {{ item.success | percentValue }}
    td.item_success-days {{ item.success_same_day | percentValue }} | {{ item.success_other_day | percentValue }}

    td.item_weight(v-if="isByLeaderOnCourseTab") {{ item.weight }}

    td.item_presentie {{ item.presentie | zeroValue }}{{ item.presentie_notignore | zeroValueWithBrackets }}

    td.item_answers(v-if="isByLeaderOnCourseTab || isByCourseLeadersTab") {{ item.filtered_by_answers | zeroValue }} | {{ item.filtered_by_answers_percents | percentValue }}

    td.item_presentie-days {{ item.presentie_notignore_same_day | zeroValue }} | {{ item.presentie_notignore_other_day | zeroValue }}
    td.item_absentie {{ item.absentie | zeroValue }}

    td.item_zero-mistakes {{ item.zero_mistakes | zeroValue }}

    td.item_failed {{ item.failed | zeroValue  }}{{ item.failed_notignore | zeroValueWithBrackets }}
    td.item_failed-days(v-if="!isByLeaderOnCourseTab") {{ item.failed_notignore_same_day | zeroValue  }} | {{ item.failed_notignore_other_day | zeroValue }}

    td.item_passed {{ item.passed | zeroValue }}
    td.item_passed-days(v-if="!isByLeaderOnCourseTab") {{ item.passed_notignore_same_day | zeroValue  }} | {{ item.passed_notignore_other_day | zeroValue }}

    td.item_mistakes_student(v-if="isByLeaderOnCourseTab") {{ item.mistakes_per_student | zeroValue }}

    td.item_category(v-if="isByLeaderOnCourseTab && item && item.category_mistakes") {{ item.category_mistakes[0] || '-' }}
    td.item_category(v-if="isByLeaderOnCourseTab && item && item.category_mistakes") {{ item.category_mistakes[1] || '-' }}
    td.item_category(v-if="isByLeaderOnCourseTab && item && item.category_mistakes") {{ item.category_mistakes[2] || '-' }}
    td.item_category(v-if="isByLeaderOnCourseTab && item && item.category_mistakes") {{ item.category_mistakes[3] || '-' }}
    td.item_category(v-if="isByLeaderOnCourseTab && item && item.category_mistakes") {{ item.category_mistakes[4] || '-' }}
    td.item_category(v-if="isByLeaderOnCourseTab && item && item.category_mistakes") {{ item.category_mistakes[5] || '-' }}
    td.item_category(v-if="isByLeaderOnCourseTab && item && item.category_mistakes") {{ item.category_mistakes[6] || '-' }}
    td.item_category(v-if="isByLeaderOnCourseTab && item && item.category_mistakes") {{ item.category_mistakes[7] || '-' }}
    td.item_category(v-if="isByLeaderOnCourseTab && item && item.category_mistakes") {{ item.category_mistakes[8] || '-' }}

    td.item_average-rating {{ item.rating | zeroValue}}
</template>

<script>
import { mapGetters } from 'vuex';
import { getMultipleQueryValueWithNumber } from '@/util'
import { COURSE_LEADERS_TABS_LIST, LEADERS_QUERY_TAB } from '../../core/courseLeadersConst'
import errorsMixin from '@/mixins/errors.mixin'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  mixins: [errorsMixin],

  data: () => {
    return {
      loading: false,
      COURSE_LEADERS_TABS_LIST,
      LEADERS_QUERY_TAB
    }
  },

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items'
    }),

    city() {
      if (!this.cities.length) return null

      let itemCity
      if (this.item && this.item.course && this.item.course.city) itemCity = this.item.course.city
      if (!itemCity) return ''

      let city = this.cities.find(city => city.ID === itemCity)
      if (city) return city.name
      else return '-'
    },

    getCourseDate() {
      if (this.item && this.item.course && this.item.course.date) return this.item.course.date
      else return ''
    },

    getCourseName() {
      if (this.item && this.item.course && this.item.course.name) return this.item.course.name
      else return ''
    },

    isByCourseTab() {
      return this.$route.query[LEADERS_QUERY_TAB] === COURSE_LEADERS_TABS_LIST.BY_COURSE;
    },

    isByCourseLeadersTab() {
      return this.$route.query[LEADERS_QUERY_TAB] === COURSE_LEADERS_TABS_LIST.BY_LEADERS;
    },

    isByLeaderOnCourseTab() {
      return this.$route.query[LEADERS_QUERY_TAB] === COURSE_LEADERS_TABS_LIST.BY_LEADER_ON_COURSE;
    }
  },

  methods: {
    isSessionActive(sessionID) {
      if (!this.$route.query.sessions) return
      let sessions = getMultipleQueryValueWithNumber(this.$route.query.sessions)
      return sessions.includes(sessionID);
    }
  },

  filters: {
    percentValue: function (value) {
      if (!value) return '-'
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1) + '%'
    },
    zeroValue: function (value) {
      if (!value) return '-'
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    zeroValueWithBrackets: function (value) {
      if (!value) return ''
      value = value.toString()
      return '(' + value.charAt(0).toUpperCase() + value.slice(1) + ')'
    },
  }
}
</script>

<style lang="scss">
.contents:nth-child(2n) tr.course-leader_item {
  background: #f1f2f6 !important;
}

.course-leader_item {
  .item {
    &_name {
      width: 150px;
      min-width: 150px;
    }

    &_date {
      width: 200px;
      min-width: 200px;
    }

    &_date-only {
      width: 120px;
      min-width: 120px;
    }

    &_quantity-course {
      width: 160px;
      min-width: 160px;
    }

    &_sessions {
      width: 105px;
      min-width: 105px;

      span.active {
        font-weight: bold;
        color: $primary-color;
      }
    }

    &_success {
      width: 100px;
      min-width: 100px;

      &-days {
        width: 150px;
        min-width: 150px;
      }
    }

    &_presentie {
      width: 120px;
      min-width: 120px;

      &-days {
        width: 140px;
        min-width: 140px;
      }
    }

    &_absentie {
      width: 100px;
      min-width: 100px;
    }

    &_zero-mistakes {
      width: 110px;
      min-width: 110px;
    }

    &_failed {
      width: 60px;
      min-width: 60px;

      &-days {
        width: 130px;
        min-width: 130px;
      }
    }

    &_passed {
      width: 90px;
      min-width: 90px;

      &-days {
        width: 125px;
        min-width: 125px;
      }
    }

    &_average-rating {
      width: 130px;
      min-width: 130px;
    }
  }
}
</style>
